export const getCurrentTime = (date: any = null): string => {
  const now = date ? new Date(date) : new Date();
  const hours = now.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if necessary
  const minutes = now.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if necessary
  return `${hours}:${minutes}`;
}

export const sortByProperty = (array: any[],propertyName: string): any[] => {
  return array.sort((a: any, b: any) =>  b[propertyName] - a[propertyName])
}
